// global css
require("./src/styles/main.scss")

// additional polyfills
require("raf/polyfill")

exports.onRouteUpdate = () => {
  if (process.env.NODE_ENV === `production`) {
    setTimeout(() => {
      if (typeof window.dataLayer !== "undefined") {
        window.dataLayer.push({ event: "gatsby-route-change" })
      }
    }, 50)
  }
}
